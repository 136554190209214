import * as THREE from 'three';
import gsap from "gsap";
import { onResize } from './resize.js';
import { loadMainScene, goToVillage, updateVillage } from './scenes/village.js';
import { initRaycaster } from './raycaster.js';
// import { annotations } from './annotations.js';
import { makeCSS2Dannotaition, initCSS2DRenderer, hideInvisibleAnns } from "./annotations.js";
import { selecting } from './select_object.js';
import { animations } from './animations.js';
import { servObj } from './init_glob.js';
import * as Camera from './Camera.js'

const container3D = document.getElementById("d3d-container");
const foreground = document.getElementById("foreground");
window.container3D = container3D;
window.container3D.foreground = foreground;
container3D.foreground.style.opacity = 0;

const renderer = new THREE.WebGLRenderer({ 
    antialias: true,
    toneMapping: THREE.ACESFilmicToneMapping,
    outputColorSpace: THREE.SRGBColorSpace,
});
const canvas = renderer.domElement;
container3D.appendChild(canvas);
renderer.setSize(container3D.offsetWidth, container3D.offsetHeight);

let annRenderer = initCSS2DRenderer(container3D);

let scene, activeCamera;
activeCamera = new Camera.Perspective(canvas);

loadMainScene(canvas, (newScene) => {
    servObj.scene = scene = newScene;
    scene.setActiveCamera = setActiveCamera;
    scene.activeCamera = activeCamera;
    
    // if (servObj.activeLocation == 'village'){
    //     goToVillage();
    // }else {
    //     servObj.activeLocation = servObj.activeLocation; //Calls all functions from servObj.onLocationChange
    // };

    initRaycaster(scene);
    
    animations.initHandler(scene);
    let cssAnnsObjs = [];
    scene.traverse(obj=>obj.name.endsWith('_ann') && cssAnnsObjs.push(obj));

    new Promise(function(res,rej){
        cssAnnsObjs.forEach((obj,indx)=>{
            servObj.cssAnns.push(makeCSS2Dannotaition(obj));

            let countAnnsAnimate = cssAnnsObjs.length - 5;

            if(indx == cssAnnsObjs.length - 1) res(countAnnsAnimate);
        });
    })
    .then((countAnnsAnimate)=>{
        setAnnsAnimate(countAnnsAnimate);
        // changeLang('.wrap_annotations ');
        if (servObj.activeLocation == 'village'){
            goToVillage();
        }else {
            servObj.activeLocation = servObj.activeLocation; //Calls all functions from servObj.onLocationChange
        };
    });

    onResize(renderer, activeCamera, container3D);
    onResize(annRenderer, activeCamera, container3D);


    // annotations.init(scene);

    selecting.init(scene);

    window.addEventListener('resize', ()=>{
        onResize(renderer, activeCamera, container3D);
        onResize(annRenderer, activeCamera, container3D);
    });

    animate();
});

const clock = new THREE.Clock();

function animate() {
    const deltaTime = clock.getDelta();

    requestAnimationFrame(animate);
    
    updateVillage(deltaTime);

    
    activeCamera.update(deltaTime);
    // annotations.update(activeCamera);


    animations.update(deltaTime);

    renderer.render(scene, activeCamera);
    annRenderer.render(scene, activeCamera);

}

function setActiveCamera(camera){
    activeCamera = camera;
    scene.activeCamera = camera;
    if (activeCamera) onResize(renderer, activeCamera, container3D);
}


function setAnnsAnimate(animAnnsCount){
    let allAnimAnnElems = document.querySelectorAll('.thing_sign');
    if(allAnimAnnElems.length < animAnnsCount){
        setTimeout(()=>setAnnsAnimate(animAnnsCount),50);
        return;
    }
    allAnimAnnElems.forEach((el,indx)=> {
        let objName = el.dataset.name;
        let obj = servObj.scene.getObjectByName(el.dataset.name);
        let text = el.querySelector('.sign_text');
        text.closest('.ann_point').style.display = 'block';
        text.style.width = 'auto';
        let animWidth = text.getBoundingClientRect().width + 54;
        text.style.width = 0;
        text.closest('.ann_point').style.display = '';
        let tween = gsap.to(
            text,
            {
                width: animWidth,
                duration: .3,
                ease: 'none',
            }
        );
        animations.annsTween[`${objName}`] = tween;
        tween.pause();
        tween.reverse();

        el.addEventListener('pointerdown', goToThingPage);

        el.addEventListener('pointerenter', (e) => {
            animations.highlightObj(obj, true);
            let currentTweens = [];
            Object.entries(animations.annsTween).forEach(([key, val]) => key.includes(servObj.activeLocation) && currentTweens.push(val));
            currentTweens.length && currentTweens.forEach(currentTween => {
                currentTween.pause();
                currentTween === tween ? currentTween.play() : currentTween.reverse();
            })
            // tween.pause();
            // tween.play();
        });

        // el.addEventListener('pointermove', (e)=>{
        //     tween.pause();
        //     tween.play();
        // });

        el.addEventListener('pointerleave', (e) => {
            animations.highlightObj(obj, false);
            tween.pause();
            tween.reverse();
        });
    });
};

function goToThingPage(e) {
    if (!servObj.activeLocation || servObj.activeLocation == 'village') return;
    // document.body.style.opacity = 0;
    // globalLoader && (globalLoader.style.display  = 'block');
    let name = e.currentTarget && e.currentTarget.dataset.name;
    setTimeout(
        () => {
            if (name) {
                location.href = `./things/${name}.html?loc=${servObj.activeLocation}&sound=${servObj.soundOn}`;
            }
        },
        500
    );
};
