import { servObj } from './app/init_glob.js';
import './app/init_3d.js';
import { getRootHref } from './insertFrame.js';
import { goToLocation } from './goToLocation.js';
import { startGame } from './startGame.js';
import { animations } from './app/animations.js';
import soundON from '../../static/images/icon/sound.svg';
import soundMute from '../../static/images/icon/sound-off.svg';

animations.annsTween = {};
const testBtns = document.querySelectorAll('.btn-to-test');
const modals = document.querySelectorAll('.modal');
const modalLinks = document.querySelectorAll('.modal-links');
const startGameWrap = document.querySelector('.start-game-wrap');
const previewBlock = document.querySelector('.preview-block');
const workshopName = document.querySelector('.workshop-name');
const soundIconWrap = document.querySelector('.sound-icon-wrap');
const soundIcon = document.querySelector('.sound-icon');
const copyBtn = document.querySelector('.copy-btn');
const copyInput = document.querySelector('.copy-input');
const globalLoader = document.getElementById('global-loader');
// let thingSigns = document.querySelectorAll('.thing_sign');
let rootHref = getRootHref();

const hoverableMeshNames = [
    'carpentry_workshop',
    'weaving_leather_workshop',
    'hand_windmill',
    'forge',
    'military_camp',

    'forge_gorn',
    'forge_molot',
    'forge_kleshi',
    'forge_nakovalnya',
    'hand_windmill_pech',
    'hand_windmill_melnitsa',
    'hand_windmill_zernoterka',
    'hand_windmill_lopata',
    'weaving_leather_workshop_stanok',
    'weaving_leather_workshop_myalka',
    'weaving_leather_workshop_vilka',
    'weaving_leather_workshop_pryalka',
    'carpentry_workshop_skobeli',
    'carpentry_workshop_teslo',
    'carpentry_workshop_topor',
    'carpentry_workshop_tokarniy_stanok',
    'military_camp_brodeks',
    'military_camp_shlem',
    'military_camp_luk',
    'military_camp_kolchuga',
];

let workshopHighLightObjs = {
    'carpentry_workshop':[
        'carpentry_workshop_exterior002',
        'carpentry_workshop_exterior003',
        'carpentry_workshop_exterior004',
        'carpentry_workshop_exterior005',
        'carpentry_workshop_exterior007',
    ],
    'weaving_leather_workshop':[
        'weaving_leather_workshop_exterior_zdanie_1',
        'weaving_leather_workshop_exterior_zdanie_2',
        'weaving_leather_workshop_exterior_zdanie_1_kozirek',
    ],
    'hand_windmill': [
        'hand_windmill_exterior_stol',
        'hand_windmill_exterior_lopata',
        'hand_windmill_exterior_pech',
        'hand_windmill_exterior_telega',
        'hand_windmill_exterior_zabor',
        'hand_windmill_exterior_zdanie_1',
        'hand_windmill_exterior_zdanie_2',
        'hand_windmill_exterior_zdanie_2001',
        'hand_windmill_exterior_zhernova',
        'Cube016',
    ],
    'forge':[
        'forge_exterior_brevna',
        'forge_exterior_gorn',
        'forge_exterior_kamni',
        'forge_exterior_lestnica',
        'forge_exterior_nakovalnya',
        'forge_exterior_pech',
        'forge_exterior_topor',
        'forge_exterior_topor001',
        'forge_exterior_topor002',
        'forge_exterior_zdanie',
    ],
    'military_camp':[
        'military_camp_exterior_lager',
        'military_camp_exterior_svyatilishe',
        'military_camp_exterior_zabor',
        'military_camp_exterior_zdanie',
    ],
};
let prevWorkshopName = null;
let intensity = 2;
const handleCursorStyle = (intersects) => {
    if(intersects.length){
        let name = intersects[0].object.name;
        document.body.style.cursor = hoverableMeshNames.includes(name) ? 'pointer' : '';
        if(Object.keys(workshopHighLightObjs).includes(name)){
            if(name == prevWorkshopName) return;
            highlightWorkshop(name,true);
            highlightWorkshop(prevWorkshopName,false);
            prevWorkshopName = name;
        }else{
            if(prevWorkshopName){
                highlightWorkshop(prevWorkshopName,false);
                prevWorkshopName = null;
            } 
        }
    }else{
        document.body.style.cursor = '';
    }   
    // document.body.style.cursor = intersects.length > 0 && hoverableMeshNames.includes(intersects[0].object.name) ? 'pointer' : '';
}

function highlightWorkshop(workshopName,needHighLight){
    let scene = servObj.scene;
    let highLightObjs = workshopHighLightObjs[workshopName];
    if(!scene || !highLightObjs || !highLightObjs.length) return;
    highLightObjs.forEach(objName=>{
        let mat = scene.getObjectByName(objName)?.material;
        if(!mat) return;
        needHighLight ? mat.emissiveIntensity *= intensity : mat.emissiveIntensity /= intensity;
    });
};


servObj.onHover3D.push(handleCursorStyle);

servObj.onLocationChange.push(goToLocation);

// function goToThingPage(e) {
//     if (!servObj.activeLocation || servObj.activeLocation == 'village') return;
//     // document.body.style.opacity = 0;
//     // globalLoader && (globalLoader.style.display  = 'block');
//     let name = e.currentTarget && e.currentTarget.dataset.name;
//     setTimeout(
//         () => {
//             if (name) {
//                 location.href = `./things/${name}.html?loc=${servObj.activeLocation}&sound=${servObj.soundOn}`;
//             }
//         },
//         500
//     );
// }

window.addEventListener('load', (e) => {
    workshopName.style.display = servObj.activeLocation !== 'village' ? 'block' : 'none';
    // if (servObj.sceneLoaded) {
    //     document.querySelectorAll('.thing_sign').forEach(el => {
    //         let objName = el.dataset.name;
    //         let obj = servObj.scene.getObjectByName(el.dataset.name);
    //         let text = el.querySelector('.sign_text');
    //         text.closest('.ann_point').style.display = 'block';
    //         text.style.width = 'auto';
    //         let animWidth = text.getBoundingClientRect().width + 54;
    //         text.style.width = 0;
    //         text.closest('.ann_point').style.display = '';
    //         let tween = gsap.to(
    //             text,
    //             {
    //                 width: animWidth,
    //                 duration: .3,
    //                 ease: 'none',
    //             }
    //         );
    //         animations.annsTween[`${objName}`] = tween;
    //         tween.pause();
    //         tween.reverse();

    //         el.addEventListener('pointerdown', goToThingPage);

    //         el.addEventListener('pointerenter', (e) => {
    //             animations.highlightObj(obj, true);
    //             let currentTweens = [];
    //             Object.entries(animations.annsTween).forEach(([key, val]) => key.includes(servObj.activeLocation) && currentTweens.push(val));
    //             currentTweens.length && currentTweens.forEach(currentTween => {
    //                 currentTween.pause();
    //                 currentTween === tween ? currentTween.play() : currentTween.reverse();
    //             })
    //             // tween.pause();
    //             // tween.play();
    //         });

    //         // el.addEventListener('pointermove', (e)=>{
    //         //     tween.pause();
    //         //     tween.play();
    //         // });

    //         el.addEventListener('pointerleave', (e) => {
    //             animations.highlightObj(obj, false);
    //             tween.pause();
    //             tween.reverse();
    //         });
    //     })
    //     document.getElementById('global-loader').style.display = 'none';
    //     servObj.currentAudioList.forEach( (audio) => {
    //         if(servObj.soundOn){
    //             !audio.type || audio.type == 'sound' ? audio.play() : setTimeout(()=>audio.play(), 2000);
    //         }else{
    //             audio.pause()
    //         };
    //     });
    // }
    // window.addEventListener('load_scene',(e)=>{
    //     document.getElementById('global-loader').style.display = 'none';
    // })

});

// window.addEventListener('load',(e)=>{
//     servObj.winLoaded = true;
//     if(servObj.sceneLoaded){
//         document.querySelectorAll('.thing_sign').forEach(el=>{
//             let objName = el.dataset.name;
//             let obj = servObj.scene.getObjectByName(el.dataset.name);
//             let text = el.querySelector('.sign_text');

//             el.style.display = 'block';
//             text.closest('.ann_point').style.display = 'block';
//             text.style.width = 'auto';
//             let animWidth = text.getBoundingClientRect().width;

//             text.style.width = 0;
//             el.style.display = '';
//             text.closest('.ann_point').style.display = '';
//             let tween = gsap.to(
//                 text, 
//                 {
//                     width: animWidth, 
//                     duration: .3, 
//                     ease: 'none',
//                 }
//             );
//             animations.annsTween[`${objName}`] = tween;
//             tween.pause();
//             tween.reverse();

//             el.addEventListener('pointerdown', goToThingPage);

//             el.addEventListener('pointerenter', (e)=>{
//                 animations.highlightObj(obj,true);
//                 tween.pause();
//                 tween.play();
//             });

//             // el.addEventListener('pointermove', (e)=>{
//             //     tween.pause();
//             //     tween.play();
//             // });

//             el.addEventListener('pointerleave', (e)=>{
//                 animations.highlightObj(obj,false);
//                 tween.pause();
//                 tween.reverse();
//             });
//         })
//         document.getElementById('global-loader').style.display = 'none';
//     }  
//     // window.addEventListener('load_scene',(e)=>{
//     //     document.getElementById('global-loader').style.display = 'none';
//     // })

// });

window.addEventListener('load_scene', (e) => {
    servObj.sceneLoaded = true;
    if(document.readyState ==  'complete'){
        document.getElementById('global-loader').style.display = 'none';
    }else{
        window.addEventListener('load',(e)=>{
            document.getElementById('global-loader').style.display = 'none';
        })
    }

    servObj.currentAudioList.forEach( (audio) => {
        if(servObj.soundOn){
            !audio.type || audio.type == 'sound' ? audio.play() : setTimeout(()=>audio.play(), 2000);
        }else{
            audio.pause()
        };
    });
});

const url = new URL(window.location.href);
let hash = window.location.hash.replace('#', '');


if (hash) {
    servObj.activeLocation = hash;
    window.location.hash = '';
    servObj.gameIsStarted = true;
}


const backpackIcons = {
    1: './images/icon/backpack_items/shirt.png',
    2: './images/icon/backpack_items/bow.png',
    3: './images/icon/backpack_items/food.png',
    4: './images/icon/backpack_items/sword.png',
    5: './images/icon/backpack_items/shield.png',
}

const testID = {
    weaving_leather_workshop: 1,
    carpentry_workshop: 2,
    hand_windmill: 3,
    forge: 4,
    military_camp: 5,
}

startGameWrap.addEventListener('pointerup', startGame);

previewBlock.style.display = servObj.gameIsStarted ? 'none' : 'flex';

let testingBtn = document.querySelector('.testing');
if (testingBtn) {
    testingBtn.style.display = servObj.gameIsStarted ? 'block' : 'none';
    testingBtn.addEventListener('pointerdown', function (e) {
        // document.body.style.opacity = 0;
        setTimeout(() => window.location.href = rootHref + `testing/testing.html?sound=${servObj.soundOn}`, 500);
    })
}

let backpackItems = Array.from(document.querySelectorAll('.backpack-item'));
backpackItems.forEach(el => el.classList.remove('not-empty'));

const testState = JSON.parse(localStorage.getItem('testState'));
setBackpackItem();

function setBackpackItem() {
    testState && Object.values(testState).forEach(state => {
        let backpackItem = backpackItems.find(el => el.dataset.testId == state.testID);
        backpackItem.classList[state.isDone ? 'add' : 'remove']('not-empty');
        backpackItem.style.backgroundImage = state.isDone ? `url(${backpackIcons[state.testID]})` : '';
    })
};

document.querySelectorAll('.accept-btn').forEach(acceptBtn => {
    acceptBtn.addEventListener('pointerdown', () => {
        Object.values(testState).forEach(state => {
            state.currentQuestion = 1;
            state.answers = { 1: 0, 2: 0, 3: 0, 4: 0 };
            state.isDone = null;
            state.isActive = false;
        });
        window.localStorage.setItem('testState', JSON.stringify(testState));
        setBackpackItem();
        closeModal();
    })
});

//открытие модалок
modalLinks.forEach(modalLink => {
    modalLink.addEventListener('pointerdown', (e) => {
        const modalName = e.currentTarget.dataset.modalName
        if (modalName) openModal(modalName)
    })
})

function openModal(targetModalName) {
    modals.forEach((modal) => {
        if (modal.id === targetModalName) {
            modal.classList.add('show')
        }
    })
}

//закрытие модалок
document.querySelectorAll('.modal-close-btn').forEach((closeIcon) => {
    closeIcon.addEventListener('pointerdown', () => {
        closeModal()
    })
})

modals.forEach((modal) => {
    modal.addEventListener('pointerdown', (event) => {
        document.querySelectorAll('.modal-close-btn').forEach((closeIcon) => {
            if (event.target !== modal) {
                return
            }
            closeModal()
        })

    })
})

function closeModal() {
    modals.forEach((modal) => {
        modal.classList.remove('show')
    })
}

//закрыть модалку по кнопке Отмена
document.querySelectorAll('.cancel-btn').forEach(cancelBtn => {
    cancelBtn.addEventListener('pointerdown', () => {
        closeModal()
    })
})

copyBtn && copyBtn.addEventListener('pointerdown', (e)=>{
    let link = copyInput && copyInput.value || 'https://holmgardvillage.ru';
    navigator.clipboard.writeText(link);
})

function goToPreviewBlock() {
    previewBlock.style.display = 'flex'
    workshopName.style.display = 'none'
}

function goToCurrentTest() {
    servObj.testIsActive = true

    const linkToTest = document.querySelector('.current-testing-link');
    linkToTest && (linkToTest.href = `./testing/testing.html?loc=${servObj.activeLocation}`)
}

function goToThings(htmlName) {
    const hrefToObject = document.querySelector('.link-to-object').href
    hrefToObject.replace('object_1', htmlName)
    window.location.href = `${hrefToObject.replace('object_1', htmlName)}?l=${servObj.activeLocation}`
}

// let turn = 0;
// startGameWrap.addEventListener('pointerenter', (e)=>{
//     let tr = getComputedStyle(startGameWrap).transform;
//     let values = tr.split(/\(|,|\)/);
//     // угол в радианах
//     let angle = Math.atan2(+values[2], +values[1]);
//     // приводим угол к диапазону 0-360
//     let degr = ((angle * (180/Math.PI)+360)%360).toFixed(0);
//     if(isNaN(degr)) degr = 0;
//     if(degr != 0 || degr>0 && degr%360 !== 0) return;
//     turn += 1;
//     degr = turn * 360;
//     startGameWrap.style.transform = `rotate(${degr}deg)`
// })

let circle = document.querySelector('.union-button__circle--first');
let symbols = document.querySelector('.union-button__symbol');
let unions = document.querySelector('.union-button__circle-symbols-wrapper');
const testGame = document.querySelector('.current-testing-wrap');

startGameWrap.addEventListener('mouseenter', function () {
    circle.classList.add('union-button__circle--active');
    symbols.classList.add('union-button__circle--active');
});

startGameWrap.addEventListener('animationend', function () {
    circle.classList.remove('union-button__circle--active');
    symbols.classList.remove('union-button__circle--active');
});

testGame.addEventListener('mouseenter', function () {
    unions.classList.add('union-button__circle-symbols-wrapper--active');
});

testGame.addEventListener('animationend', function () {
    unions.classList.remove('union-button__circle-symbols-wrapper--active');
});

let soundOn = servObj.soundOn;
soundIcon.style.backgroundImage = soundOn ? `url("${soundON}")` : `url("${soundMute}")`

// let soundOn = true;
// servObj.soundOn = true;

soundIconWrap.addEventListener('pointerdown', () => {
    soundOn = !soundOn
    soundIcon.style.backgroundImage = soundOn ? `url("${soundON}")` : `url("${soundMute}")`

    servObj.soundOn = soundOn;
    servObj.currentAudioList.forEach( (audio) => {
        if (!soundOn) audio.pause();
        else audio.play();
    });
})


const verticalModal = document.querySelector('.vertical-modal');

function checkOrientation() {
    // Проверяем ширину и высоту экрана
    if (window.innerWidth < window.innerHeight) {
        // Портретная ориентация
        verticalModal.classList.add('vertical-modal--active');
    } else {
        // Альбомная ориентация
        verticalModal.classList.remove('vertical-modal--active');
    }
}

// Проверяем ориентацию при загрузке страницы
checkOrientation();

// Проверяем ориентацию при изменении размера экрана
window.addEventListener('resize', checkOrientation);

document.addEventListener("DOMContentLoaded", () => {
    const modalBodies = document.querySelectorAll('.modal-body-gradient');
    const modalGradients = document.querySelectorAll('.modal__gradient');

    const checkScroll = (modalBody, modalGradient) => {
        const hasScroll = modalBody.scrollHeight > modalBody.clientHeight;
        modalGradient.style.display = hasScroll ? "block" : "none";
    };

    modalBodies.forEach((modalBody, index) => {
        const modalGradient = modalGradients[index];
        if (!modalGradient) return;

        const handleScroll = () => {
            const scrollTop = modalBody.scrollTop;
            const scrollHeight = modalBody.scrollHeight;
            const clientHeight = modalBody.clientHeight;

            // Проверяем, дошел ли пользователь до конца
            if (scrollTop + clientHeight >= scrollHeight) {
                modalGradient.classList.add('modal__gradient--none');
            } else {
                modalGradient.classList.remove('modal__gradient--none');
            }
        };

        const observer = new MutationObserver(() => checkScroll(modalBody, modalGradient));
        observer.observe(modalGradient, { childList: true, subtree: true });

        window.addEventListener("resize", () => checkScroll(modalBody, modalGradient));
        modalBody.addEventListener('scroll', handleScroll);

        // Инициализируем проверку прокрутки при загрузке
        checkScroll(modalBody, modalGradient);
    });
});
