import * as THREE from 'three';
import * as Camera from '../Camera.js';
import { setVisibility } from '../visibility_recursive.js';
import { selecting } from '../select_object.js';
import { animations } from '../animations.js';
import { materials } from '../materials.js';
import { locationLoadStatus, loadLocationTextures } from './village.js'
import { servObj } from '../init_glob.js';
import { gsap } from "gsap";

const globalLoader = document.getElementById('global-loader');


const interiorProperties = {
    'carpentry_workshop': {
        camera: null,
        maxTruckOffset: {x: .1, y: .1},
        hoverable: [
            'carpentry_workshop_tokarniy_stanok',
            'carpentry_workshop_teslo',
            'carpentry_workshop_skobeli',
            'carpentry_workshop_topor',
        ],
    },
    'weaving_leather_workshop': {
        camera: null,
        maxTruckOffset: {x: .2, y: .2},
        hoverable: [
            'weaving_leather_workshop_stanok',
            'weaving_leather_workshop_myalka',
            'weaving_leather_workshop_vilka',
            'weaving_leather_workshop_pryalka',
        ],
    },
    'hand_windmill': {
        camera: null,
        maxTruckOffset: {x: .1, y: .06},
        hoverable: [
            'hand_windmill_pech',
            'hand_windmill_melnitsa',
            'hand_windmill_zernoterka',
            'hand_windmill_lopata',
        ],
    },
    'forge': {
        camera: null,
        maxTruckOffset: {x: .1, y: .1},
        hoverable: [
            'forge_gorn',
            'forge_molot',
            'forge_kleshi',
            'forge_nakovalnya',
        ],
    },
    'military_camp': {
        camera: null,
        maxTruckOffset: {x: 1, y: 1},
        hoverable: [
            'military_camp_brodeks',
            'military_camp_shlem',
            'military_camp_luk',
            'military_camp_kolchuga',
        ],
    },
}
export const interiors = {
    init: initInteriors,
    goInside: goInside,
    interiorProperties: interiorProperties
}

let scene;

export function initInteriors(canvas, pScene){
    scene = pScene;
    
    Object.keys(interiorProperties).forEach(interiorName => setUpCamera(canvas, interiorName));
    
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('forge_pyl_anim'), videoElemID: 'dust-video', playbackRate: .7, maxAppearOpacity: .8, fadePercentage: .1 });
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('forge_dym_anim'), videoElemID: 'smoke-video', playbackRate: 1, maxAppearOpacity: .6, fadePercentage: .1 });
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('hand_windmill_pyl_anim'), videoElemID: 'dust-video', playbackRate: .7, maxAppearOpacity: .8, fadePercentage: .1 });
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('hand_windmill_dym_anim'), videoElemID: 'smoke-video', playbackRate: 1, maxAppearOpacity: .6, fadePercentage: .1 });
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('weaving_leather_workshop_pyl_anim'), videoElemID: 'dust-video', playbackRate: .7, maxAppearOpacity: .8, fadePercentage: .1 });

    animations.randomGlow({ obj: scene.getObjectByName('forge_ugli'), minIntensity: .5, maxIntensity: 1.5, minDuration: 0.9, maxDuration: 1.3, });
    animations.randomGlow({ obj: scene.getObjectByName('hand_windmill_ugli'), minIntensity: .5, maxIntensity: 1.5, minDuration: 0.9, maxDuration: 1.3, });
}

export function goInside(interiorName, sceneObjectContainers){
    sceneObjectContainers.forEach(obj => setVisibility(obj, false));
    const interiorParent = scene.getObjectByName(interiorName + '_interior_pivot');
    setVisibility(interiorParent, true);

    scene.setActiveCamera(interiorProperties[interiorName].camera);

    selecting.setSelectable(interiorProperties[servObj.activeLocation].hoverable);

    handleAudio(interiorName);
    
    animations.fadeIn({
        onComplete(){
            if(globalLoader && globalLoader.style.display != 'none') return;
            servObj.currentAudioList.forEach( (audio) => {
                if(servObj.soundOn){
                    audio.type == 'sound' ? audio.play() : setTimeout(()=>audio.play(), 2000);
                }else{
                    audio.pause()
                };
            });
        }
    });
}

function setUpCamera(canvas, interiorName) {
    const importedCamera = scene.getObjectByName(interiorName + '_camera');
    const bg = scene.getObjectByName(interiorName + '_fon');

    const interiorCamera = interiorProperties[interiorName].camera = new Camera.Perspective(canvas, importedCamera.fov * .85, importedCamera.aspect);
    interiorCamera.viewportFit.type = Camera.ViewportFitFill;
    interiorCamera.controls.setLookAt(importedCamera.position.x, importedCamera.position.y, importedCamera.position.z, bg.position.x, bg.position.y, bg.position.z);
    interiorCamera.controls.polarRotateSpeed = 0;
    interiorCamera.controls.azimuthRotateSpeed = 0;
    interiorCamera.controls.dollySpeed = 0;
    interiorCamera.controls.truckSpeed = 0.01;
    
    interiorCamera.setTruckOnPointerMove(true, interiorProperties[interiorName].maxTruckOffset);

    // Camera bounds
    // const camBoundaryBoxGeo = new THREE.BoxGeometry( .15, .15, .15 ); 
    // const camBoundaryBoxMesh = new THREE.Mesh( camBoundaryBoxGeo ); 
    // camBoundaryBoxMesh.position.copy(importedCamera.position);
    // camBoundaryBoxMesh.rotation.copy(importedCamera.rotation);
    // const camBoundaryBox3 = new THREE.Box3().setFromObject(camBoundaryBoxMesh);
    // interiorCamera.controls.setBoundary(camBoundaryBox3);
    // interiorCamera.controls.boundaryEnclosesCamera = true;
}

function handleAudio(interiorName) {
    servObj.currentAudioList.forEach( (audio) => {
        gsap.to(audio, {
            volume: 0,
            duration: 1,
            onComplete: () => {audio.pause(); audio.currentTime = 0;}
        });
    });
    servObj.currentAudioList = [];
    
    const sound = new Audio('./audio/' + interiorName + '_sound.mp3');
    sound && (sound.loop = true, sound.type = 'sound');
    
    const voice = new Audio('./audio/' + interiorName + '_voice.mp3');
    voice && (voice.type = 'voice');

    // if (servObj.soundOn) sound.play();
    // voice.play();

    gsap.fromTo(sound, { volume : 0 }, {
        volume: .08,
        duration: 2,
    });
    gsap.fromTo(voice, { volume : 0 }, {
        volume: .35,
        duration: 1,
        // delay: 2,
        // onStart: () => {
        //     if (servObj.soundOn) voice.play();
        // }
    });

    servObj.currentAudioList.push(sound, voice);
}