import { goToLocation } from "./goToLocation";
import { onStartBtnClicked } from "./app/scenes/village";

let testingBtn = document.querySelector('.testing');
// let backpak = document.querySelector('.backpack-wrap');


export function startGame() {
    
    servObj.gameIsStarted = true;
    let d3dContainer = document.getElementById('d3d-container');
    d3dContainer && (d3dContainer.style.pointerEvents = 'none');
    goToLocation('village');
    onStartBtnClicked();

    document.querySelector('.preview-block').style.display = 'none';
    testingBtn && (testingBtn.style.display = 'block');
    // backpak && (backpak.style.display = 'block');

}
