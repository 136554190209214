import * as THREE from "three";
import { animations } from "./animations.js";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

extendMeshBasicMaterial();

const materialList = [];

export const materials = {
	list: materialList,
	setMaterial: setMaterial,
	setAnimatedOverlay: setAnimatedOverlay,
};

export function setMaterial(object) {
	let existingMaterial;
	if (materialList.length > 0) {
		existingMaterial = materialList.find((mat) => mat.name === object.material.name);
	}

	if (existingMaterial) {
		object.material = existingMaterial.isUnique ? existingMaterial.clone() : existingMaterial;
		return;
	}

	if (object.material.type === "MeshBasicMaterial") {
		// let oldMaterial = object.material.clone();
		// object.material = customBasicMaterial();
		// object.material.copy(oldMaterial);
	}
	
	materialList.push(object.material);
}

function customBasicMaterial() {
	const material = new CustomShaderMaterial({
		baseMaterial: THREE.MeshBasicMaterial,
		vertexShader: ``,
		fragmentShader: `
		uniform float uEmissiveIntensity;
        void main(){
			csm_DiffuseColor *= vec4(vec3(uEmissiveIntensity), 1);
        }
        `,
	});

	material.uniforms = { uEmissiveIntensity: { value: 1 } };
    Object.defineProperty(material, 'emissiveIntensity', {
        set: function(newValue) {
            this.uniforms.uEmissiveIntensity.value = newValue;
        },
        get: function() {
            return this.uniforms.uEmissiveIntensity.value;
        }
    });

	return material;
}

export async function setAnimatedOverlay({ obj, videoElemID, playbackRate = 1, maxAppearOpacity = 1, fadePercentage } = {}) {
	const video = document.getElementById(videoElemID);

	const setUp = () => { 		
		video.play();
		video.playbackRate = playbackRate;
		
		let tabFocused = true;
		document.addEventListener("visibilitychange", function () {
			tabFocused = !tabFocused;
			tabFocused ? video.play() : video.pause();
		});

		const animatedMatParams = {
			map: new THREE.VideoTexture(video),
			color: new THREE.Color(0xffffff),
			blending: THREE.CustomBlending,
			blendDst: THREE.OneFactor,
			blendSrc: THREE.SrcColorFactor,
			transparent: true,
			opacity: 0,
		};
		animatedMatParams.map.flipY = false;
		const animatedMaterial = new THREE.MeshBasicMaterial(animatedMatParams);
		obj.material = animatedMaterial;

		animations.animatedMaterialAppear({
			material: animatedMaterial,
			duration: video.duration / video.playbackRate,
			maxOpacity: maxAppearOpacity,
			fadePercentage: fadePercentage,
		});
	};

	if (obj && video.duration){
		setUp();
	}else{
		setTimeout(()=>setUp(), 50)
		// video.onloadedmetadata = (event) => { setUp() };
	} 
}
function extendMeshBasicMaterial() {
    // Add the emissiveIntensity property with a default value
    Object.defineProperty(THREE.MeshBasicMaterial.prototype, 'emissiveIntensity', {
        get: function() {
            return this._emissiveIntensity !== undefined ? this._emissiveIntensity : 1.0;
        },
        set: function(value) {
            this._emissiveIntensity = value;
            if (this.userData && this.userData.shader) {
                // Update the shader uniform if the shader is already compiled
                this.userData.shader.uniforms.emissiveIntensity.value = value;
            }
        }
    });

    // Override the onBeforeCompile method to inject custom shader code
    THREE.MeshBasicMaterial.prototype.onBeforeCompile = function(shader) {
        // Add the emissiveIntensity uniform to the shader
        shader.uniforms.emissiveIntensity = { value: this.emissiveIntensity };

        // Inject the emissiveIntensity uniform declaration at the beginning of the fragment shader
        shader.fragmentShader = 'uniform float emissiveIntensity;\n' + shader.fragmentShader;

        // Modify the fragment shader code to multiply the output color by emissiveIntensity
        shader.fragmentShader = shader.fragmentShader.replace(
            'vec4 diffuseColor = vec4( diffuse, opacity );',
            'vec4 diffuseColor = vec4( diffuse * emissiveIntensity, opacity );'
        );

        // Store a reference to the shader to update the uniform later if needed
        this.userData.shader = shader;
    };
}