import { Vector2, Raycaster } from 'three';

const raycaster = new Raycaster();

const pointer = new Vector2();

const objects = [
    'instanced_grid',
    'military_camp',
    'forge',
    'hand_windmill',
    'carpentry_workshop',
    'weaving_leather_workshop',
    
    'forge_fon',
    'forge_gorn',
    'forge_molot',
    'forge_kleshi',
    'forge_nakovalnya',

    'hand_windmill_fon',
    'hand_windmill_pech',
    'hand_windmill_melnitsa',
    'hand_windmill_zernoterka',
    'hand_windmill_lopata',

    'weaving_leather_workshop_fon',
    'weaving_leather_workshop_korzina',
    'weaving_leather_workshop_stanok',
    'weaving_leather_workshop_myalka',
    'weaving_leather_workshop_vilka',
    'weaving_leather_workshop_pryalka',

    'carpentry_workshop_fon',
    'carpentry_workshop_zadniy_fon',
    'carpentry_workshop_skobeli',
    'carpentry_workshop_teslo',
    'carpentry_workshop_topor',
    'carpentry_workshop_tokarniy_stanok',

    
    'military_camp_fon',
    'military_camp_brodeks',
    'military_camp_shlem',
    'military_camp_luk',
    'military_camp_kolchuga',
];

let scene;

export function initRaycaster(pScene){
    scene = pScene;
    objects.forEach( (oName, i) => objects[i] = scene.getObjectByName(oName) );
    let canvas = container3D.querySelector('canvas');
    canvas && canvas.addEventListener('pointermove', onPointerMove );
    canvas && canvas.addEventListener('click', onClick );
}

function onPointerMove(event){
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

    const intersects = getIntersects();
    if(!intersects) return;

    servObj.onHover3D.forEach((callback) => callback(intersects));
}

function onClick(){
    const intersects = getIntersects();
    if(!intersects) return;
    servObj.onClick3D.forEach((callback) => callback(intersects));
}

function getIntersects(){
    const camera = scene.activeCamera;
    raycaster.setFromCamera( pointer, camera );
    const intersects = raycaster.intersectObjects( objects );
    return intersects.length > 0 && intersects;
}

