import gsap from "gsap";
import { servObj } from "./init_glob.js";
import * as THREE from "three";

export const animations = {
	initHandler: initHandler,
	update: update,

	importedAnimPlay: importedAnimPlay,
	importedAnimStop: importedAnimStop,
	importedAnimPaused: importedAnimPaused,

	randomGlow: randomGlow,
	fadeIn: fadeIn,
	fadeOut: fadeOut,
	animatedMaterialAppear: animatedMaterialAppear,
	highlightObj: highlightObj,

    goInside: goInside,
	startGame: startGame,
};

let mixer, clips, scene;
function initHandler(pScene) {
    scene = pScene;
	mixer = new THREE.AnimationMixer(scene);
	clips = scene.animations;
}

function update(deltaTime) {
	mixer.update(deltaTime);
}

function importedAnimPlay({
	clipNameIncludes = "",
	callbackOfssetS = 0,
	loop = THREE.LoopOnce,
	onComplete = () => {},
} = {}) {
	const frameClips = clips.filter((clip) => clip.name.includes(clipNameIncludes));
	frameClips.forEach((clip) => {
		const action = mixer.clipAction(clip);
		action.paused = false;
		action.loop = loop;
		action.play();
	});

	const durationS = frameClips[0].duration / mixer.timeScale;
	
	const delayedCall = gsap.delayedCall(durationS + callbackOfssetS, () => {
		onComplete();
	});
	delayedCall.vars.id = clipNameIncludes;
}

function importedAnimStop(clipNameIncludes = "") {
	const frameClips = clips.filter((clip) => clip.name.includes(clipNameIncludes));
	frameClips.forEach((clip) => mixer.clipAction(clip).stop());
	gsap.getById(clipNameIncludes)?.kill();
}

function importedAnimPaused(clipNameIncludes = "", paused) {
	const frameClips = clips.filter((clip) => clip.name.includes(clipNameIncludes));
	frameClips.forEach((clip) => (mixer.clipAction(clip).paused = paused));
	gsap.getById(clipNameIncludes)?.paused(paused);
}

function randomGlow({
	obj,
	minIntensity = 0,
	maxIntensity = 1,
	minDuration = 0.5,
	maxDuration = 1,
} = {}) {
	const to = THREE.MathUtils.randFloat(minIntensity, maxIntensity);
	const duration = THREE.MathUtils.randFloat(minDuration, maxDuration);
	gsap.to(obj.material, {
		emissiveIntensity: to,
		duration: duration,
		ease: "power1.inOut",
		onComplete: () => randomGlow({ 
			obj: obj,
			minIntensity: minIntensity,
			maxIntensity: maxIntensity,
			minDuration: minDuration,
			maxDuration: maxDuration,
		}),
	});
}

function fadeIn({ duration = 1, onComplete } = {}) {
	gsap.to(container3D.foreground.style, {
		opacity: 0,
		duration: duration,
		ease: "power1.inOut",
		onComplete: onComplete,
	});
	servObj.cssAnns.forEach(cssObj=>cssObj.element.classList[cssObj.element.className.includes(servObj.activeLocation) ? 'add' : 'remove']('visible'));

}

function fadeOut({ duration = 1, onComplete } = {}) {
	gsap.to(container3D.foreground.style, {
		opacity: 1,
		duration: duration,
		ease: "power1.inOut",
		onComplete(){
			onComplete();
		},
	});
}

function animatedMaterialAppear({ material, duration, maxOpacity = 0.6, fadePercentage = .2 } = {}) {
	maxOpacity = Math.round(maxOpacity * 255).toString(16);
	const maxOpacityHEX = `#${maxOpacity}${maxOpacity}${maxOpacity}`;

	const colorFadeIn = {
		from: "#000000",
		to: maxOpacityHEX,
	}; 
	const colorFadeOut = {
		from: maxOpacityHEX,
		to: "#000000",
	};
	const tl = gsap.timeline({ repeat: -1 });
	tl.to(colorFadeIn, {
		from: colorFadeIn.to,
		duration: duration * fadePercentage,
		onUpdate: () => {
			material.color = new THREE.Color(colorFadeIn.from);
		},
	}).to(colorFadeOut, {
		from: colorFadeOut.to,
		delay: duration * (1 - fadePercentage * 2),
		duration: duration * fadePercentage,
		onUpdate: () => {
			material.color = new THREE.Color(colorFadeOut.from);
		},
	});
}

function highlightObj(obj, selected) {
	gsap.to(obj.material, {
		emissiveIntensity: selected ? 3 : 1,
		duration: 0.25,
		ease: "power1.inOut",
	});
}

function goInside({ controls, locMesh, onComplete = () => {} } = {}){

    document.body.style.pointerEvents = 'none';
	servObj.cssAnns.forEach(cssObj=>cssObj.element.classList.remove('visible'));

    //Center the camera controls on a workshop
	const tl = gsap.timeline();

	const worldLocMeshPos = new THREE.Vector3();
	locMesh.getWorldPosition(worldLocMeshPos);
    tl.to([controls._target, controls._targetEnd], { 
        x: worldLocMeshPos.x, 
        y: worldLocMeshPos.y, 
        z: worldLocMeshPos.z, 
        duration: 5, 
        ease: "power2.inOut",
    }, 0);

    //Zoom the camera controls on a workshop
    tl.to(controls, { 
        _zoom: 4.5, 
        _zoomEnd: 4.5, 
        duration: 5, 
        ease: "power2.in",
    }, 0);
	// tl.to(controls, { 
    //     _zoom: 3.5, 
    //     _zoomEnd: 3.5,
    //     duration: 1, 
    //     ease: "power1.in",
    // }, '>1.5');

    //Fade out
    tl.to(container3D.foreground.style, { 
        opacity: 1, 
        duration: 1, 
        ease: "power1.out",
        onComplete: () => {
            // controls.enabled = true;
            onComplete();
            document.body.style.pointerEvents = '';
        }
    }, '>-1');
}

function startGame(camera, scene) {
	const tl = gsap.timeline();
	const currentZoom = camera.controls._zoomEnd
	const currentTarget = camera.controls._targetEnd
	
	tl.fromTo(camera.controls, {_zoom: .855, _zoomEnd: .855,}, { 
        _zoom: 1.359, 
        _zoomEnd: 1.359, 
        duration: 5, 
        ease: "power3.inOut",
    }, 2);

	const clouds = scene.getObjectByName('gorodishe_clouds');
	tl.fromTo(clouds.material, {opacity: 1}, { 
        opacity: 0,
        duration: 5, 
        ease: "power3.inOut",
    }, 2);

    tl.fromTo([camera.controls._target, camera.controls._targetEnd], {x: -21.8, z: 24.71},  { 
        x: -40.76, 
        z: 43.35, 
		duration: 5, 
        ease: "power3.inOut",
    }, 2)
	.then(()=>{
		let d3dContainer = document.getElementById('d3d-container');
		d3dContainer && (d3dContainer.style.pointerEvents = '');
		let player = scene.getObjectByName('player_pivot');
		if(player){
			let emissiveIntensity = player.children[0]?.material?.emissiveIntensity; 
			if(emissiveIntensity){
				tl.fromTo(
					player.children[0].material,
					{emissiveIntensity},
					{
						emissiveIntensity: 3,
						duration: .5,
						yoyoEase: true,
						repeat: 5,
					}
				)
			}
		}
	});
	
}