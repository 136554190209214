import * as THREE from "three";
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import { servObj } from './init_glob.js';
import { locationName } from '../goToLocation.js';

const engLocName = Object.keys(locationName);

// const thingthName = {
// 	weaving_leather_workshop_vilka: 'Вилка для плетения шнура',
// 	weaving_leather_workshop_stanok: 'Ткацкий станок',
// 	weaving_leather_workshop_pryalka: 'Прялка',
// 	weaving_leather_workshop_myalka: 'Мялка',
// 	military_camp_shlem: 'Шлем',
// 	military_camp_luk: 'Лук',
// 	military_camp_kolchuga: 'Кольчуга',
// 	military_camp_brodeks: 'Бродэкс',
// 	hand_windmill_zernoterka: 'Зернотерка',
// 	hand_windmill_pech: 'Печь',
// 	hand_windmill_melnitsa: 'Жернова',
// 	hand_windmill_lopata: 'Садник',
// 	forge_nakovalnya: 'Наковальня',
// 	forge_molot: 'Молот',
// 	forge_kleshi: 'Клещи',
// 	forge_gorn: 'Горн',
// }

const thingthName = {
        carpentry_workshop_skobeli: 'СКОБЕЛЬ',
        carpentry_workshop_teslo: 'ТЕСЛО',
        carpentry_workshop_tokarniy_stanok: 'ТОКАРНЫЙ&nbsp;СТАНОК',
        carpentry_workshop_topor: 'ТОПОР',
        military_camp_brodeks:'БРОДЭКС',
        military_camp_kolchuga:'КОЛЬЧУГА',
        military_camp_luk:'ЛУК',
        military_camp_shlem:'ШЛЕМ',
        hand_windmill_melnitsa: 'ЖЕРНОВА',
        hand_windmill_pech: 'ПЕЧЬ',
        hand_windmill_lopata: 'САДНИК',
        hand_windmill_zernoterka: 'ЗЕРНОТЕРКА',
        forge_nakovalnya: 'НАКОВАЛЬНЯ ',
        forge_gorn: 'ГОРН',
        forge_kleshi: 'КЛЕЩИ',
        forge_molot: 'МОЛОТ',
        weaving_leather_workshop_stanok: 'ТКАЦКИЙ&nbsp;СТАНОК',
        weaving_leather_workshop_vilka: 'ВИЛКА',
        weaving_leather_workshop_myalka: 'МЯЛКА',
        weaving_leather_workshop_pryalka: 'ПРЯЛКА',
}


export function makeCSS2Dannotaition(object, innerHTML = 'AnnContent'){

	if(!object || typeof object != 'object' || !object.isObject3D) return;

	// let rotateLine_1 = document.createElement( 'div' );
	// rotateLine_1.classList.add('rotation_line_1');
	// rotateLine_1.style.position = 'absolute';
	// rotateLine_1.style.top = '0';
	// rotateLine_1.style.right = '0';

	// let line_1 = document.createElement( 'div' );
	// line_1.classList.add('line_1');
	// line_1.style.position = 'absolute';
	// line_1.style.top = '0';
	// line_1.style.left = '0';

    // rotateLine_1.append(line_1);

    // let rotateLine_2 = document.createElement( 'div' );
	// rotateLine_2.classList.add('rotation_line_2');
	// rotateLine_2.style.position = 'absolute';
	// rotateLine_2.style.top = '0';
	// rotateLine_2.style.right = '0';

    // let line_2 = document.createElement( 'div' );
	// line_2.classList.add('line_2');
	// line_2.style.position = 'absolute';
	// line_2.style.top = '0';
	// line_2.style.left = '0';

    // let lineText = document.createElement( 'div' );
	// lineText.textContent = text;
	// lineText.dataset.text = object.name;

	// lineText.classList.add('line_text');
	// lineText.style.position = 'absolute';
	// lineText.style.bottom = '0';
	// lineText.style.left = '50%';
	// lineText.style.transform = 'translateX(-50%)';

    // line_2.append(lineText);
    // rotateLine_2.append(line_2);
    // line_1.append(rotateLine_2);
	let objType = object.name.replace('_ann', '');
	let objClass;
	let pointLabel = document.createElement( 'div' );
	pointLabel.classList.add('ann_point_label');
	pointLabel.style.position = 'absolute';
	if(engLocName.includes(objType)){
		objClass = 'village_ann';
		innerHTML = `
			<img src="./images/icon/village/${objType}.svg" alt="${locationName[objType]}">
		`
	}else{
		objClass = `${objType}_ann`;
		innerHTML = `
			<img src="./images/icon/triangle_left.png" alt="">
			<div class="sign_text"><span>${thingthName[objType]}</span></div>
			<img src="./images/icon/triangle_right.png" alt="">
			<div class="back_plane"></div>
		`;
		// <img class="sign_text" src="./images/icon/text_empty.svg" alt=""">
		pointLabel.dataset.name = objType;
		pointLabel.classList.add('thing_sign');
	}
	pointLabel.innerHTML = innerHTML;
	
	let pointHTML = document.createElement( 'div' );
	pointHTML.id = `ann_${object.name || object.id}`;
	pointHTML.classList.add('ann_point', objClass);
	// pointHTML.style.pointerEvents = 'auto';
	pointHTML.append(pointLabel);
	// pointHTML.append(rotateLine_1, pointLabel);
	
	//*************** pointer handler *********************
	// pointHTML.addEventListener('pointerdown', (e)=>{
    //     if(!servObj.showAllAnns){
    //         Array.from(document.querySelectorAll('.ann_point_label')).filter(el=>el != pointLabel).forEach(el=>el.classList.remove('visible'));
    //         Array.from(document.querySelectorAll('.line_1, .line_2, .line_text')).filter(el=>el != line_1 && el != line_2 && el != lineText).forEach(el=>el.classList.remove('line_visible'));
    //     }; 
	// 	pointLabel.classList.toggle('visible');
	// 	line_1.classList.toggle('line_visible');
	// 	line_2.classList.toggle('line_visible');
	// 	lineText.classList.toggle('line_visible');
	// });

	let point2D = new CSS2DObject(pointHTML);
	point2D.name = 'css2d_' + object.name || 'id_' + object.id;
	object.add(point2D);

	servObj && servObj.annotations && servObj.annotations.push(point2D);
	return point2D;
}

export function initCSS2DRenderer(container){
    let labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize( container.innerWidth, container.innerHeight );
    labelRenderer.domElement.style.position = 'absolute';
    labelRenderer.domElement.style.top = '0px';
    labelRenderer.domElement.style.pointerEvents = 'none';
    labelRenderer.domElement.classList.add('wrap_annotations');
    document.body.appendChild( labelRenderer.domElement );
    return labelRenderer;
}

let raycaster = new THREE.Raycaster(); 
let v3_1 = new THREE.Vector3(); 
let v3_2 = new THREE.Vector3(); 
let v3_3 = new THREE.Vector3();


export function hideInvisibleAnns(camera, annotaitions){
    if(!camera.isCamera || !annotaitions.length || !servObj.toDecomp || !servObj.showAllAnns) return;
	
	annotaitions.forEach(ann=>{
		getWorldPositionNorm(camera, v3_1);
		getWorldPositionNorm(ann, v3_2);
		v3_3.copy(v3_2).sub(v3_1).normalize();
		raycaster.set(v3_1, v3_3);
		raycaster.far = v3_1.distanceTo(v3_2);
		let i = raycaster.intersectObject(servObj.app.scene.children[0], !0);
		// let i = raycaster.intersectObject(findRootObj(ann), !0);
		ann.element.style.display = i.length > 1 || 1 == i.length && i[0].object != ann.parent ? 'none' : 'block';
		ann.element.querySelectorAll('.ann_point_label, .line_1, .line_2, .line_text').forEach(el=>el.classList[i.length > 1 || 1 == i.length && i[0].object != ann.parent ? 'remove' : 'add']('visible', 'line_visible'));
	
	});
}

function getWorldPositionNorm(obj, v3 = new THREE.Vector3()){
    if(!obj) return;
    return v3.setFromMatrixPosition(obj.matrixWorld)
}
function findRootObj(obj) {
    let e = obj;
    return obj.traverseAncestors((function(t){e = t})), e;
}


// export function hideInvisibleAnns(camera, annotaitions){
//     if(!camera.isCamera || !annotaitions.length || !servObj.toDecomp) return;
// 	let objDist = {};
// 	let arrDist = [];

// 	annotaitions.forEach(obj=>{
//         let dist = camera.getWorldPosition(new THREE.Vector3()).distanceTo(obj.getWorldPosition(new THREE.Vector3()));
// 		objDist[dist] = obj.element;
//     });
// 	arrDist = Object.keys(objDist).sort((a,b)=>a-b);
// 	arrDist.forEach((el,indx)=>{
// 		if(indx < arrDist.length/2){
// 			objDist[el].style.display =  'block';
// 		}else{
// 			objDist[el].querySelectorAll('.ann_point_label, .line_1, .line_2, .line_text').forEach(el=>el.classList.remove('visible', 'line_visible'));
// 			objDist[el].style.display =  'none';
// 		}
// 	});
// }






// const elements = document.querySelectorAll(".annotation-3d");
// const annotations3D = [];

// export const annotations = {
// 	elements: annotations3D,
// 	init,
// 	update: update,
// 	setAnnVisibility,
// 	setTextVisibility,
// }

// function init(scene) {
// 	for (const el of elements) {
// 		const annID = el.id.split("ann_")[1] + '_ann'; //
// 		const object = scene.getObjectByName(annID);
// 		if (!object) return;
// 		annotations3D.push({
// 			element: el,
// 			position: object.position,
// 		});
// 	}
// }

// function update(camera) {
// 	for (const ann of annotations3D) {
// 		const screenPosition = ann.position.clone();
// 		screenPosition.project(camera);

// 		const translateX = screenPosition.x * container3D.offsetWidth * 0.5;
// 		const translateY = -screenPosition.y * container3D.offsetHeight * 0.5;
// 		ann.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
// 	}
// }

// function setAnnVisibility(objectName, visible) {
// 	elements.forEach(elem=>elem.classList.remove('visible'));	
// 	let elGroup = document.querySelectorAll(".ann_" + objectName);
// 	// const el = document.querySelector("#ann_" + objectName);
// 	// if (el) visible ? (el.classList.add("visible")) : (el.classList.remove("visible"));
// 	elGroup && elGroup.forEach(elem=>{
// 		elem.classList[visible ? 'add' : 'remove']("visible")
// 	})
// }

// function setTextVisibility(objectName, visible) {
// 	const el = document.querySelector("#ann_" + objectName);
// 	if (!el) return;
// 	const children = [...el.children];
// 	const annText = children.find((child) => child.className.includes("ann-text"));
// 	if (!annText) return;
// 	visible ? annText.classList.add("visible") : annText.classList.remove("visible");
// }
