import { servObj } from "./app/init_glob"
// import "./app/annotations.js"

export const locationName = {
    carpentry_workshop: './images/icon/carpentry-workshop-name.svg',
    weaving_leather_workshop: './images/icon/weaving-workshop-name.svg',
    hand_windmill: './images/icon/hand-mill-name.svg',
    forge: './images/icon/forge-name.svg',
    military_camp: './images/icon/military-camp-name.svg',
}

export const locationButtonUnion = {
    carpentry_workshop: './images/icon/carpentry-workshop-union.svg',
    weaving_leather_workshop: './images/icon/weaving-workshop-union.svg',
    hand_windmill: './images/icon/hand-mill-union.svg',
    forge: './images/icon/forge-union.svg',
    military_camp: './images/icon/military-camp-union.svg',
}

export const locationNameWidth = {
    carpentry_workshop: 'carpentry-workshop-name',
    weaving_leather_workshop: 'weaving-leather-workshop-name',
    hand_windmill: 'hand-mill-workshop-name',
    forge: 'forge-workshop-name',
    military_camp: 'military-workshop-name',
}

export function goToLocation(locName) {
    let testing = document.querySelector('.testing');
    testing && (testing.style.display = servObj.gameIsStarted && locName == 'village' ? 'block' : 'none');

    let workshopName = document.querySelector('.workshop-name');
    workshopName.style.display = locName == 'village' ? 'none' : 'block';
    workshopName.src = locationName[locName] || '';
    if (workshopName) {
        Object.values(locationNameWidth).forEach(className => {
            if (workshopName.classList.contains(className)) {
                workshopName.classList.remove(className);
            }
        });

        if (locationNameWidth[locName]) {
            workshopName.classList.add(locationNameWidth[locName]);
        }
    }

    let backpak = document.querySelector('.backpack-wrap');
    backpak && (backpak.style.display = locName !== 'village' && servObj.gameIsStarted ? 'block' : 'none');

    document.querySelector('.back-to-village-wrap').style.display = locName !== 'village' && servObj.gameIsStarted ? 'block' : 'none';
    document.querySelector('.current-testing-wrap').style.display = locName !== 'village' ? 'block' : 'none';
    document.querySelector('.union-button__circle-symbols').src = locName !== 'village' ? locationButtonUnion[locName] : '';

    if(document.readyState == 'complete'){
        setAnnsVisible(locName);
    }else{
        window.addEventListener('load', ()=>setAnnsVisible(locName));
    };
}

function setAnnsVisible(locName) {
    servObj.cssAnns.forEach(cssObj => cssObj.element.classList[cssObj.element.className.includes(locName) ? 'add' : 'remove']('visible'));
}
