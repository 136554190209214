export const servObj = window.servObj = {};

servObj.testIsActive = false
servObj.soundOn = true
servObj.gameIsStarted = false;
servObj.isAllDownload = false

servObj.onHover3D = [];
servObj.onClick3D = [];
servObj.onLocationChange = [];
servObj.cssAnns = [];
let activeLocation = 'village';
Object.defineProperty(servObj, 'activeLocation', {
    set: function(newValue) {
        activeLocation = newValue;
        servObj.onLocationChange.forEach(callback => callback(activeLocation));
    },
    get: function() { return activeLocation }
});
// servObj.activeLocation = 'village';

servObj.currentAudioList = [];