import soundON from '../../static/images/icon/sound.svg'
import soundMute from '../../static/images/icon/sound.svg'
import { servObj } from './app/init_glob.js';
import { gsap } from "gsap";

export function getRootHref(href = window.location.href) {
    let loc = window.location;
    let indx = href.indexOf('index');
    let root = href.includes('testing') ? href.slice(0, href.indexOf('testing')) :
        href.includes('thing') ? href.slice(0, href.indexOf('thing')) :
            href.slice(0, indx == -1 ? href.length : indx);
    return root.replace(loc.search, '').replace(loc.hash, '');
}

let rootHref = getRootHref();

(async function () {

    if (!window.servObj) {
        window.servObj = await import("./app/init_glob.js");
    }

    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('loc')) servObj.activeLocation = searchParams.get('loc');
    if (servObj.activeLocation && servObj.activeLocation !== 'village') servObj.gameIsStarted = true;

    const container = document.querySelector('.container')
    let href = window.location.href;

    // <a class="logo-link" href="${href.includes('/testing') ? '../index.html':'./index.html'}"></a>

    const header = `
        <header class="header">
            <div class="logo"></div>
            <ul class="menu-list">
                <li class="menu-item modal-links" data-modal-name="about-project">О проекте</li>
                <li class="menu-item testing">Тестирование</li>
                <li class="menu-item modal-links" data-modal-name="project-partners">Авторы</li>
            </ul>
            <div class="controls">
                <div class="sound-icon-wrap">
                    <div class="sound-icon"></div>
                </div>
                <div class="help-icon-wrap modal-links" data-modal-name="help-modal">
                    <div class="help-icon"></div>
                </div>
                <div class="reset-icon-wrap modal-links" data-modal-name="clear-storage">
                    <div class="reset-icon button-back  button-back-close"></div>
                </div>
            </div>
        </header>
    `

    const indexFooter = `
        <div class="footer">
            <div class="back-to-village-wrap">
                <button class="back-to-village button-back button">Вернуться в Городище</button>
            </div>
            <div class="current-testing-wrap">
                <button class="testing-btn">
                    <div class="union-button">
                        <div class=union-button__circle-symbols-wrapper>
                            <img class="union-button__circle-symbols" src="" alt="">
                        </div>
                        <div class="union-button__circle union-button__circle--second">
                            <p>Пройти тест</p>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    `

    const testingFooter = `
        <div class="footer">
            <div class="backto-btns-wrap">
                <div class="back-to-workshop-wrap">
                    <button class="button-back button-back-close button"></button>
                </div>
                <div class="back-to-village-wrap-testing">
                    <button class="button-back button-back-close button"></button>
                </div>
            </div>
        </div>
    `

    const thingsFooter = `
        <div class="footer">
            <div class="back-to-workshop-wrap">
                <div class="back-to-workshop-link">
                    <button class="button-back back-to-workshop button-back-close button"></button>
                </div>
            </div>
        </div>
    `

    function setMaskHTML() {

        if (!href || !container) return;

        container.insertAdjacentHTML('afterbegin', header);

        if (href.includes('/things/')) {
            container.insertAdjacentHTML('beforeend', thingsFooter)
        } else if (href.includes('/testing/')) {
            container.insertAdjacentHTML('beforeend', testingFooter);
        }
        else {
            container.insertAdjacentHTML('beforeend', indexFooter)
        }

    };

    setMaskHTML();

    let bacToVillageBtn = document.querySelector('.back-to-village-wrap');
    let bacToVillageTestingBtn = document.querySelector('.back-to-village-wrap-testing');
    let bacToWorkshopBtn = document.querySelector('.back-to-workshop-wrap');
    let passWorkroomTestingBtn = document.querySelector('.current-testing-wrap');
    let soundIcon = document.querySelector('.sound-icon');
    let soundIconWrap = document.querySelector('.sound-icon-wrap');
    let currentTestingLink = document.querySelector('.current-testing-link');
    let testing = document.querySelector('.testing');
    let logo = document.querySelector('.logo');
    // let testState = JSON.parse(window.localStorage.getItem('testState'));

    function backToVillage() {
        let pathname = window.location.pathname;
        if (pathname.includes('testing')) {
            let testState = JSON.parse(window.localStorage.getItem('testState'));
            if(testState){
                Object.values(testState).forEach(testState=>testState.isActive = servObj.testIsActive = false);
                window.localStorage.setItem('testState', JSON.stringify(testState));
            };
            window.location.href = rootHref + `?needSetCoord=true&sound=${servObj.soundOn}#village`;
        } else if (pathname.includes('things')) {
            window.location.href = rootHref + `?needSetCoord=true&sound=${servObj.soundOn}#village`;
        }

        if (!servObj.gameIsStarted || servObj.activeLocation == 'village') return;
        servObj.activeLocation = 'village';

    }

    logo && logo.addEventListener('pointerdown', backToVillage);

    if (bacToVillageBtn) {
        bacToVillageBtn.addEventListener('pointerdown', () => {
            servObj.currentAudioList.forEach( (audio) => {
                gsap.to(audio, {
                    volume: 0,
                    duration: 1,
                    onComplete: () => {audio.pause(); audio.currentTime = 0;}
                });
            });
        
            servObj.activeLocation = 'village';
            servObj.cssAnns.forEach(cssObj=>cssObj.element.classList.remove('visible'));
            let testState = JSON.parse(window.localStorage.getItem('testState'));
            if(testState){
                Object.values(testState).forEach(testState=>testState.isActive = servObj.testIsActive = false);
                window.localStorage.setItem('testState', JSON.stringify(testState));
            };
        });

        (bacToVillageBtn.style.display = servObj.activeLocation == 'village' && servObj.gameIsStarted ? 'block' : 'none');
    };

    bacToVillageTestingBtn && bacToVillageTestingBtn.addEventListener('pointerdown', backToVillage);


    if (passWorkroomTestingBtn) {
        passWorkroomTestingBtn.style.display = servObj.activeLocation !== 'village' ? 'block' : 'none';
        passWorkroomTestingBtn.addEventListener('pointerup', function (e) {
            // document.body.style.opacity = 0;
            const location = servObj.activeLocation;
            if (location) {
                setTimeout(()=>window.location.href = `./testing/testing.html?loc=${location}&sound=${servObj.soundOn}`, 500);
                // testState && Object.values(testState).forEach(test => {
                //     if(test.testID === testID[location]) {
                //         test.isDone ? openModal('repeat-test-warning') : goToCurrentTest()
                //     }
                // })
                // !testState && goToCurrentTest()
            }
        });
    };

    if (bacToWorkshopBtn) {
        bacToWorkshopBtn.style.display = servObj.activeLocation && servObj.activeLocation !== 'village' ? 'block' : 'none';
        bacToWorkshopBtn.addEventListener('pointerdown', (e) => {
            let testState = JSON.parse(window.localStorage.getItem('testState'));

            if(testState){
                Object.values(testState).forEach(testState=>testState.isActive = servObj.testIsActive = false);
                window.localStorage.setItem('testState', JSON.stringify(testState));
            };
            location.href = rootHref + `?needSetCoord=true&sound=${servObj.soundOn}#${servObj.activeLocation}`;
        })
    }

    passWorkroomTestingBtn && (passWorkroomTestingBtn.style.display = 'none');
    servObj.soundOn = searchParams.get('sound') && searchParams.get('sound') != 'true' ? false : true;

    soundIconWrap && soundIconWrap.addEventListener('pointerdown', () => {
        servObj.soundOn = !servObj.soundOn
        soundIcon.style.backgroundImage = servObj.soundOn ? `url("${soundON}")` : `url("${soundMute}")`;
    });

    soundIcon && (soundIcon.style.backgroundImage = servObj.soundOn ? `url("${soundON}")` : `url("${soundMute}")`);

    testing.addEventListener('pointerup', function (e) {
        // servObj.testIsActive = true;
        servObj.gameIsStarted = true;
    });
})()

