import * as THREE from 'three';
import * as Camera from '../Camera.js';
import { loader } from '../loader.js';
import grid from '../grid.js';
import { gsap } from "gsap";
import { setVisibility } from '../visibility_recursive.js';
import { pathfinder } from '../pathfinding.js';
import { initInteriors } from './interior.js';
import { goInside } from './interior.js';
import { animations } from '../animations.js';
import { materials } from '../materials.js';
import { applyCustomProperties } from '../custom_properties.js';
import { selecting } from '../select_object.js';

const scene = new THREE.Scene();

// export const village = {
//     load: load
// };

let villageCamera;
let player;

let movingToLocation = undefined;

const cameraForwardOffset = 0;
const defaultZoom = 1;

let moveTimeline = gsap.timeline();
let colorTimeline = gsap.timeline();

let sceneObjectContainers;
const sceneObjectContainerNames = [
    'main_scene_pivot',
    'instanced_grid',
    'grid',
    'carpentry_workshop_interior_pivot',
    'weaving_leather_workshop_interior_pivot',
    'hand_windmill_interior_pivot',
    'forge_interior_pivot',
    'military_camp_interior_pivot',
];

let villageObjects;
const villageObjectsNames = [
    'main_scene_pivot',
    'instanced_grid',
    'grid',
];

const clickableObjectNames = [
    'instanced_grid',
    'carpentry_workshop',
    'weaving_leather_workshop',
    'hand_windmill',
    'forge',
    'military_camp',
]

const texturesToLoad = {
    village: [
        'gorodishe_ukrepleniya.webp',
        'gorodishe_pol.webp',
        'gorodishe_doroga.webp',
        'gorodishe_zdaniya.webp',
        'gorodishe_dym.webp',
        'gorodishe_clouds.webp',
        'village_player.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_brevna_1.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_brevna_2.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_pol.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_stol.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_zdanie_1.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_zdanie_2.webp',
        'carpentry_workshop_exterior/carpentry_workshop_exterior_zdanie_3.webp',
        'forge_exterior/forge_exterior_brevna.webp',
        'forge_exterior/forge_exterior_gorn.webp',
        'forge_exterior/forge_exterior_kamni.webp',
        'forge_exterior/forge_exterior_lestnica.webp',
        'forge_exterior/forge_exterior_nakovalnya.webp',
        'forge_exterior/forge_exterior_pech.webp',
        'forge_exterior/forge_exterior_pol.webp',
        'forge_exterior/forge_exterior_topor.webp',
        'forge_exterior/forge_exterior_zdanie.webp',
        'hand_windmill_exterior/hand_windmill_exterior_lopata.webp',
        'hand_windmill_exterior/hand_windmill_exterior_pech.webp',
        'hand_windmill_exterior/hand_windmill_exterior_pol.webp',
        'hand_windmill_exterior/hand_windmill_exterior_stol.webp',
        'hand_windmill_exterior/hand_windmill_exterior_telega.webp',
        'hand_windmill_exterior/hand_windmill_exterior_zabor.webp',
        'hand_windmill_exterior/hand_windmill_exterior_zdanie_1.webp',
        'hand_windmill_exterior/hand_windmill_exterior_zdanie_2.webp',
        'hand_windmill_exterior/hand_windmill_exterior_zhernova.webp',
        'military_camp_exterior/military_camp_exterior_lager.webp',
        'military_camp_exterior/military_camp_exterior_pol.webp',
        'military_camp_exterior/military_camp_exterior_svyatilishe.webp',
        'military_camp_exterior/military_camp_exterior_zabor.webp',
        'military_camp_exterior/military_camp_exterior_zdanie.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_pol.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_prochee.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_pryalka.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_sherst.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_stanok.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_zabor.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_zdanie_1.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_zdanie_1_kozirek.webp',
        'weaving_leather_workshop_exterior/weaving_leather_workshop_exterior_zdanie_2.webp',
    ],
    carpentry_workshop: [
        'carpentry_workshop_brevno.webp',
        'carpentry_workshop_bruski.webp',
        'carpentry_workshop_doma_1.webp',
        'carpentry_workshop_doma_2.webp',
        'carpentry_workshop_doski.webp',
        'carpentry_workshop_fon.webp',
        'carpentry_workshop_konstruktsiya.webp',
        'carpentry_workshop_opilki.webp',
        'carpentry_workshop_pyl_1.webp',
        'carpentry_workshop_pyl_2.webp',
        'carpentry_workshop_pyl_3.webp',
        'carpentry_workshop_pyl_4.webp',
        'carpentry_workshop_skameyka.webp',
        'carpentry_workshop_skobeli.webp',
        'carpentry_workshop_stol.webp',
        'carpentry_workshop_teslo.webp',
        'carpentry_workshop_tokarniy_stanok.webp',
        'carpentry_workshop_tokarniy_stanok_palka.webp',
        'carpentry_workshop_tokarniy_stanok_pen.webp',
        'carpentry_workshop_topor.webp',
        'carpentry_workshop_tsep.webp',
        'carpentry_workshop_zadniy_fon.webp',
    ],
    weaving_leather_workshop: [
        'weaving_leather_workshop_fon.webp',
        'weaving_leather_workshop_hlam.webp',
        'weaving_leather_workshop_korzina.webp',
        'weaving_leather_workshop_korzina_2.webp',
        'weaving_leather_workshop_motok.webp',
        'weaving_leather_workshop_myalka.webp',
        'weaving_leather_workshop_pryalka.webp',
        'weaving_leather_workshop_pryazha.webp',
        'weaving_leather_workshop_pryazha_2.webp',
        'weaving_leather_workshop_pyl.webp',
        'weaving_leather_workshop_pyl_2.webp',
        'weaving_leather_workshop_pyl_kraska.webp',
        'weaving_leather_workshop_pyl_kraska_2.webp',
        'weaving_leather_workshop_pyl_kraska_3.webp',
        'weaving_leather_workshop_skamya.webp',
        'weaving_leather_workshop_stanok.webp',
        'weaving_leather_workshop_svet.webp',
        'weaving_leather_workshop_vilka.webp',
    ],
    hand_windmill: [
        'hand_windmill_drova.webp',
        'hand_windmill_dym.webp',
        'hand_windmill_dym_2.webp',
        'hand_windmill_dym_3.webp',
        'hand_windmill_dym_4.webp',
        'hand_windmill_fon.webp',
        'hand_windmill_gorshki.webp',
        'hand_windmill_grabli.webp',
        'hand_windmill_karkas.webp',
        'hand_windmill_kotelki.webp',
        'hand_windmill_kotelok.webp',
        'hand_windmill_lopata.webp',
        'hand_windmill_melnitsa.webp',
        'hand_windmill_meshki.webp',
        'hand_windmill_pech.webp',
        'hand_windmill_snopy.webp',
        'hand_windmill_snopy_2.webp',
        'hand_windmill_stol.webp',
        'hand_windmill_stolb.webp',
        'hand_windmill_stul.webp',
        'hand_windmill_ugli.webp',
        'hand_windmill_vedro.webp',
        'hand_windmill_zernoterka.webp',
    ],
    forge: [
        'forge_balka.webp',
        'forge_bochka.webp',
        'forge_dym.webp',
        'forge_dym_2.webp',
        'forge_dym_3.webp',
        'forge_fon.webp',
        'forge_gorn.webp',
        'forge_kadushka.webp',
        'forge_kleshi.webp',
        'forge_klinki.webp',
        'forge_molot.webp',
        'forge_nakovalnya.webp',
        'forge_stol_1.webp',
        'forge_stol_2.webp',
        'forge_ugli.webp',
        'forge_valun.webp',
        'forge_vedro.webp',
    ],
    military_camp: [
        'military_camp_brodeks.webp',
        'military_camp_dom.webp',
        'military_camp_dym_1.webp',
        'military_camp_dym_2.webp',
        'military_camp_dym_3.webp',
        'military_camp_dym_4.webp',
        'military_camp_dym_5.webp',
        'military_camp_fon.webp',
        'military_camp_kanaty.webp',
        'military_camp_kolchuga.webp',
        'military_camp_lager.webp',
        'military_camp_lager_2.webp',
        'military_camp_lar.webp',
        'military_camp_luk.webp',
        'military_camp_mech.webp',
        'military_camp_ograda.webp',
        'military_camp_palatka_1.webp',
        'military_camp_palatka_2.webp',
        'military_camp_palatka_3.webp',
        'military_camp_palatka_4.webp',
        'military_camp_palatka_4_podporki.webp',
        'military_camp_shlem.webp',
        'military_camp_stena.webp',
        'military_camp_stoyka.webp',
        'military_camp_taburet.webp',
    ],
};

export const locationLoadStatus = {
    village: 'not-loaded',
    forge: 'not-loaded',
    carpentry_workshop: 'not-loaded',
    weaving_leather_workshop: 'not-loaded',
    hand_windmill: 'not-loaded',
    military_camp: 'not-loaded',
};

const customProperties = {
    gorodishe_dym: {
		renderOrder: 100,
        material: {
            // depthTest: false,
        }
	},
    gorodishe_clouds: {
		renderOrder: 110,
        material: {
            opacity: 0,
            // depthTest: false,
        }
	},

    hand_windmill_karkas: {
		renderOrder: -100
	},
    hand_windmill_kotelki: {
		renderOrder: -90
	},
	hand_windmill_fon: {
		renderOrder: -200
	},
    hand_windmill_melnitsa: {
        renderOrder: -90
    },
    hand_windmill_grabli: {
        renderOrder: -95
    },
    hand_windmill_pech: {
        renderOrder: -85
    },
    hand_windmill_drova: {
        renderOrder: -82
    },
    hand_windmill_stul: {
        renderOrder: -75
    },
    hand_windmill_lopata: {
        renderOrder: -70
    },
    hand_windmill_gorshki: {
        renderOrder: -80
    },
    hand_windmill_meshki: {
        renderOrder: -81
    },
    hand_windmill_snopy: {
        material: {
            depthTest: false
        },
        renderOrder: 1
    },
    hand_windmill_dym: {
        material: {
            depthTest: false,
            emissiveIntensity: 5,
        },
        renderOrder: 1,
    },
    hand_windmill_dym_2: {
        material: {
            emissiveIntensity: 5
        }
    },
    hand_windmill_dym_3: {
        material: {
            emissiveIntensity: 5
        }
    },
    hand_windmill_dym_4: {
        material: {
            emissiveIntensity: 5
        }
    },
    hand_windmill_dym_anim: {
        material: {
            depthTest: false
        },
        renderOrder: 2,
    },

    weaving_leather_workshop_pryalka: {
        material: {
            isUnique: true,
        },
        renderOrder: 80
    },
    weaving_leather_workshop_svet: {
        material: {
            emissiveIntensity: 2
        }
    },
    weaving_leather_workshop_pyl_kraska: {
        material: {
            emissiveIntensity: 5
        }
    },
    weaving_leather_workshop_pyl_kraska_2: {
        material: {
            emissiveIntensity: 5
        },
        renderOrder: 90
    },
    weaving_leather_workshop_pyl_kraska_3: {
        material: {
            emissiveIntensity: 5
        },
        renderOrder: -90
    },
    weaving_leather_workshop_pyl: {
        material: {
            emissiveIntensity: 5
        }
    },
    weaving_leather_workshop_pyl_2: {
        material: {
            emissiveIntensity: 5
        },
        renderOrder: -90
    },
    weaving_leather_workshop_fon: {
        renderOrder: -110
    },
    weaving_leather_workshop_hlam: {
        renderOrder: -100
    },
    weaving_leather_workshop_korzina: {
        renderOrder: 100
    },
    weaving_leather_workshop_motok: {
        renderOrder: 100
    },
    weaving_leather_workshop_pyl_anim: {
        renderOrder: 200,
        material: {
            depthTest: false
        },
    },

    carpentry_workshop_stol: {
        renderOrder: 930,
    },
    carpentry_workshop_teslo: {
        renderOrder: 1000,
    },
    carpentry_workshop_topor: {
        renderOrder: 1000,
    },
    carpentry_workshop_brevno: {
        renderOrder: 950,
    },
    carpentry_workshop_pyl_2_1: {
        material: {
            emissiveIntensity: 5,
        },
        renderOrder: 940,
    },
    carpentry_workshop_pyl_1: {
        material: {
            emissiveIntensity: 5,
        },
        renderOrder: 1000,
    },
    carpentry_workshop_pyl_2_2: {
        material: {
            emissiveIntensity: 5,
        },
        renderOrder: 20,
    },
    carpentry_workshop_pyl_3: {
        material: {
            emissiveIntensity: 5,
        },
        renderOrder: 10,
    },
    carpentry_workshop_pyl_4: {
        material: {
            emissiveIntensity: 5,
        },
    },
    carpentry_workshop_konstruktsiya: {
        renderOrder: 900,
    },
    carpentry_workshop_skameyka: {
        renderOrder: 910,
    },
    carpentry_workshop_brevno_nogi_1: {
        renderOrder: 910,
    },
    carpentry_workshop_bruski: {
        renderOrder: 850,
    },
    carpentry_workshop_brevna_fon: {
        material: {
            depthWrite: true,
        },
        renderOrder: 100,
    },
    carpentry_workshop_doski_fon: {
        renderOrder: 100,
    },
    carpentry_workshop_doski: {
        renderOrder: 800,
    },
    carpentry_workshop_tsep: {
        renderOrder: 950,
    },
    carpentry_workshop_skobeli: {
        renderOrder: 951,
    },

    military_camp_brodeks: {
        renderOrder: 1000,
    },
    military_camp_dym_1: {
        material: {
            emissiveIntensity: 5,
        },
    },
    military_camp_dym_2: {
        material: {
            emissiveIntensity: 2,
        },
    },
    military_camp_dym_3: {
        material: {
            emissiveIntensity: 2,
        },
    },
    military_camp_dym_4: {
        material: {
            emissiveIntensity: 1,
        },
        renderOrder: -100,
    },
    military_camp_dym_5: {
        material: {
            emissiveIntensity: 2,
        },
    },
    military_camp_lager_2: {
        renderOrder: -150,
    },
    military_camp_stena: {
        renderOrder: -200,
    },
    military_camp_palatka_4_naves: {
        material: {
            depthWrite: true
        },
    }
}

let loadScene = new Event('load_scene',{bubbles: true});


export function loadMainScene(canvas, onLoad) {
    let sceneLoadPromise = new Promise(function(resolve, reject) {
        loader.loadGLTF('./3d/holmgard.glb', (gltf) => { 
            scene.add(gltf.scene);

            resolve();
            
            initVillage(canvas);
            initInteriors(canvas, scene);

            const getObject = (name) => scene.getObjectByName(name);
            villageObjects = villageObjectsNames.map(getObject);
            sceneObjectContainers = sceneObjectContainerNames.map(getObject);

            servObj.onLocationChange.push((locName) => {
                selecting.setSelectable([]);
                if(locName == 'village') {
                    animations.fadeOut({ onComplete: goToVillage });
                    return;
                }
                goInside(locName, sceneObjectContainers);
            });

            if (onLoad) onLoad(scene);    

            applyCustomProperties(scene, null, customProperties);

        });
    });

    const loadInteriors = () => {
        const nextToLoad = Object.keys(locationLoadStatus).find((key) => locationLoadStatus[key] == 'not-loaded');
        
        // setTimeout(() => {
        loadLocationTextures(nextToLoad, sceneLoadPromise, loadInteriors);
        // }, 5000)
    }
    loadLocationTextures('village', sceneLoadPromise, loadInteriors);
}

export function loadLocationTextures(locationName, promise, onLoad){
    
    locationLoadStatus[locationName] = 'loading';

    loader.loadTextures('./textures/' + locationName + '_textures', texturesToLoad[locationName], async (textures) => {

        locationLoadStatus[locationName] = 'loaded';

        if (onLoad) onLoad();
        
        if (promise) await promise;
        
        textures.forEach(texture => {
            let mats = materials.list.filter(mat => {
                if(texture.name.includes('/')) return mat.name === texture.name.split('/')[1].split('.')[0]
                else return mat.name === texture.name.split('.')[0]  
            });
            
            if (!mats) return;
            mats.forEach(material => {
                if(locationName == 'village' && material.name != 'gorodishe_dym' && material.name != 'gorodishe_clouds') {
                    material.depthWrite = true;
                    material.alphaTest = .5;
                    // material.alphaHash = true;
                }
                material.map = texture
                material.needsUpdate = true;
            });
        });
        
        if(servObj.activeLocation == locationName) document.body.dispatchEvent(loadScene);
    });
}

export function goToVillage() {
    sceneObjectContainers.forEach(obj => setVisibility(obj, false));
    villageObjects.forEach(obj => setVisibility(obj, true));

    const worldPlayerPos = new THREE.Vector3();
    player.getWorldPosition(worldPlayerPos);
    villageCamera.controls.moveTo(worldPlayerPos.x + cameraForwardOffset, worldPlayerPos.y, worldPlayerPos.z, true);
    villageCamera.controls.zoomTo(defaultZoom);
    
    scene.setActiveCamera(villageCamera);

    selecting.setSelectable(null);
    
    animations.fadeIn();

    servObj.currentAudioList.forEach( (audio) => {
        gsap.to(audio, {
            volume: 0,
            duration: 1,
            onComplete: () => {audio.pause(); audio.currentTime = 0;}
        });
    });
    servObj.currentAudioList = [];
}

export function onStartBtnClicked(){
    animations.startGame(villageCamera, scene);

    // const voice = new Audio('./audio/intro.mp3');
    // gsap.fromTo(voice, { volume : 0 }, {
    //     volume: .35,
    //     duration: 1,
    // });
    // servObj.soundOn ? voice.play() : voice.pause();
    // servObj.currentAudioList.push(voice);
}

export function updateVillage(deltaTime) {

    if (movingToLocation) {
        const worldPlayerPos = new THREE.Vector3();
        player.getWorldPosition(worldPlayerPos);
        villageCamera.controls.moveTo( worldPlayerPos.x + cameraForwardOffset, worldPlayerPos.y, worldPlayerPos.z, true );
    }
}

function initVillage(canvas) {

    setUpCamera(canvas);

    const gridDivisions = 100;
    const cellSize = 2;

    const instancedGrid = setUpGrid(gridDivisions, cellSize);
    
    setUpPlayer();

    servObj.onClick3D.push( (intersects) => { 
        moveToClicked(intersects, gridDivisions, instancedGrid) 
    });

    servObj.onHover3D.push( (changes) => {
        // highlightHoveredCell(changes);
    });

    const exteriors = [
        'carpentry_workshop_exterior',
        'weaving_leather_workshop_exterior',
        'hand_windmill_exterior',
        'forge_exterior',
        'military_camp_exterior',
    ]
    exteriors.forEach(oName => {
        const obj = scene.getObjectByName(oName);
        obj.children.forEach(child => { if (child.material) child.material.emissiveIntensity = 2 } )
    })
}

function setUpCamera(canvas) {
    let importedCamera = scene.getObjectByName('village_camera');
    villageCamera = new Camera.Orthographic(canvas, importedCamera.left, importedCamera.right, importedCamera.top, importedCamera.bottom);
    villageCamera.copy(importedCamera, true);
    villageCamera.rotation.set(0, 0, 0);
    villageCamera.frustumSize = villageCamera.right * 2;
    
    villageCamera.controls.setPosition(importedCamera.position.x, importedCamera.position.y, importedCamera.position.z);
    villageCamera.controls.rotateTo(-0.8003789701639575, 0.9541803125538296);
    villageCamera.controls.verticalDragToForward = true;
    villageCamera.controls.polarRotateSpeed = .2;
    villageCamera.controls.azimuthRotateSpeed = .2;
    villageCamera.controls.minAzimuthAngle = -.85 + Math.PI / 4;
    villageCamera.controls.maxAzimuthAngle = -.75 + Math.PI / 4;
    villageCamera.controls.minPolarAngle = .9;
    villageCamera.controls.maxPolarAngle = 1.0;
    villageCamera.controls.minZoom = 1;
    villageCamera.controls.maxZoom = 3;
    villageCamera.controls.zoomTo(defaultZoom);
    villageCamera.viewportFit.type = Camera.ViewportFitFill;
    
    villageCamera.setRotateOnPointerMove(true);

    const camBoundaryBox3 = new THREE.Box3(new THREE.Vector3(-29, .1, -24), new THREE.Vector3(-10, .1, 51))

    villageCamera.controls.addEventListener('control', () => {
        const newBounds = new THREE.Box3().copy(camBoundaryBox3)
        const expandValue = THREE.MathUtils.mapLinear(villageCamera.controls._zoomEnd, villageCamera.controls.minZoom, villageCamera.controls.maxZoom, 0, 30)
        villageCamera.controls.setBoundary(newBounds.expandByScalar(expandValue)); 
    });

    scene.rotation.y = Math.PI / 4;
    scene.getObjectByName('interiors').rotation.y = -scene.rotation.y;

    villageCamera.controls.setBoundary(camBoundaryBox3);

}

function setUpGrid(gridDivisions, cellSize) {
    // grid(scene, gridDivisions, cellSize, 'grid');
    
    const obstaclesArray = [];
    const obstacleContainer = scene.getObjectByName('obstacle_container');
    setVisibility(obstacleContainer, false);
    obstacleContainer.children.forEach( (mesh) => {
        const box = new THREE.Box3();
        mesh.geometry.computeBoundingBox();
        box.copy( mesh.geometry.boundingBox ).applyMatrix4( mesh.matrixWorld );
        obstaclesArray.push(box);
    });

    pathfinder.init(gridDivisions, cellSize, obstaclesArray);
    
    const instancedGrid = pathfinder.createInstancedGrid(cellSize, 'instanced_grid');
    scene.add(instancedGrid);
    
    return instancedGrid;
}

function setUpPlayer(){
    let searchParams = new URLSearchParams(window.location.search);
    let href = window.location.href;
    let origin = window.location.origin;
    let pathname = window.location.pathname;
    let rootHref = href.includes('testing') ? href.slice(0, href.indexOf('test')) : 
                    href.includes('thing') ? href.slice(0, href.indexOf('thing')):
                    origin + pathname;
    let needSetCoord = searchParams.get('needSetCoord');
    
    needSetCoord && window.history.pushState(null,null,rootHref);
    
    let currentCoord = localStorage.getItem('currentCoord')?.split(',').map(el=>Number(el)) || [];
    player = scene.getObjectByName('player_pivot');
    player.currentNode = (needSetCoord && currentCoord.length == 2) ? currentCoord : [3, 50]; 
    // localStorage.setItem('currentCoord', '');
    
    player.position.copy(pathfinder.findPath(player.currentNode[0], player.currentNode[1], player.currentNode[0], player.currentNode[1]).positions[0]);

    villageCamera.controls.moveTo( player.position.x + cameraForwardOffset, player.position.y, player.position.z, false );
}

function createPath(intersects, gridDivisions){
    movingToLocation = undefined;
    
    let x = Math.trunc(intersects[0].instanceId / gridDivisions);
    let y = intersects[0].instanceId % gridDivisions;
    
    const locationNodes = {
        instanced_grid: [x, y],
        military_camp: [63, 46],
        forge: [51, 44],
        hand_windmill: [36, 55],
        carpentry_workshop: [35, 42],
        weaving_leather_workshop: [30, 49],
    }
    movingToLocation = intersects[0].object.name;
    
    if (!locationNodes[movingToLocation]) return;

    x = locationNodes[movingToLocation][0];
    y = locationNodes[movingToLocation][1];

    const path = pathfinder.findPath(player.currentNode[0], player.currentNode[1], x, y)
    path.nodes.shift();
    path.positions.shift();

    return path;
}

function resetTimelines(instancedGrid){
    moveTimeline.kill();
    moveTimeline = gsap.timeline({ 
        onComplete: () => {
            if (movingToLocation != 'instanced_grid') {
                const locMesh = scene.getObjectByName(movingToLocation);
                
                const tmpMovingToLocation = movingToLocation;
                movingToLocation = undefined;
                const onComplete = () => {                   
                    servObj.activeLocation = tmpMovingToLocation;          
                }
                animations.goInside({ controls: villageCamera.controls, locMesh: locMesh, onComplete: onComplete });

                return;
            }

            movingToLocation = undefined;
        },
    });

    colorTimeline.kill();
    colorTimeline = gsap.timeline({ 
        delay: .5, 
        onComplete: () => pathfinder.clearInstancedColor(instancedGrid),
        onInterrupt: () => pathfinder.clearInstancedColor(instancedGrid),
    });
}

function animateMoving(gridDivisions, instancedGrid, path){
    path.positions.forEach( (pos, i) => {
        //Player moving animation
        moveTimeline.to(player.position, {
            x: pos.x, 
            z: pos.z, 
            duration: .25,
            ease: "none", 
            onComplete: () => {
                player.currentNode = path.nodes[i];
                localStorage.setItem('currentCoord', player.currentNode);
            },
        });
        
        /*
        //Instanced cells color animation
        const matrixIndex =  path.nodes[i][0] * gridDivisions + path.nodes[i][1];
        const color = {
            from: '#00FF00',
            to: '#000000'
        }
        instancedGrid.setColorAt(matrixIndex, new THREE.Color(color.from))
        instancedGrid.instanceColor.needsUpdate = true;
        colorTimeline.to(color, {
            from: color.to,
            duration: .25,
            ease: "none",
            onUpdate: () => {
                instancedGrid.setColorAt(matrixIndex, new THREE.Color(color.from))
                instancedGrid.instanceColor.needsUpdate = true;
            },
        });
        */
    });
}

function moveToClicked(intersects, gridDivisions, instancedGrid){

    const clickedObjName = intersects[0].object.name;
    if (!clickableObjectNames.includes(clickedObjName)) return;

    const path = createPath(intersects, gridDivisions);
    
    resetTimelines(instancedGrid);
    
    animateMoving(gridDivisions, instancedGrid, path);

}

function highlightHoveredCell(intersects){
    if (intersects[0].object !== instancedGrid) return;

    pathfinder.clearInstancedColor(instancedGrid);

    const matrixIndex = intersects[0].instanceId;
    instancedGrid.setColorAt(matrixIndex, new THREE.Color('#00FF00'))
    instancedGrid.instanceColor.needsUpdate = true;
}