// import { annotations } from './annotations.js';
import { animations } from './animations.js';

export const selecting = {
    init: init,
    setSelectable: setSelectable,
}
const globalLoader = document.getElementById('global-loader');


let selectable;
let selectedObject;
let scene;
let prevHoveredObject;

function init(pScene){
    scene = pScene;

    servObj.onHover3D.push( (intersects) => highlightHoveredObject(intersects[0].object) );
    servObj.onClick3D.push( (intersects) =>{
        setSelectedObject(intersects[0].object);
    });
}

function setSelectable(objNames){
    selectable = objNames
}

function highlightHoveredObject(hoveredObject) {
    if (!selectable) return;
    if (
        prevHoveredObject && 
        prevHoveredObject !== hoveredObject &&
        prevHoveredObject !== selectedObject &&
        selectable.includes(prevHoveredObject.name)
    )
    {
        highlightObject(prevHoveredObject, false);
        let objName = prevHoveredObject.name;
        if(animations.annsTween[objName]){
            animations.annsTween[objName].pause();
            animations.annsTween[objName].reverse();
        }
    }
    
    if (hoveredObject && selectable.includes(hoveredObject.name)) {
        highlightObject(hoveredObject, true);
        let objName = hoveredObject.name;
        if(animations.annsTween[objName]){
            animations.annsTween[objName].pause();
            animations.annsTween[objName].play();
        }
    }

    prevHoveredObject = hoveredObject;
}

function setSelectedObject(object) {
    if (!selectable) return;
    selectedObject = object;
    // if (selectedObject) annotations.setTextVisibility(selectedObject.name, true);
    if (selectedObject && selectable.includes(selectedObject.name)){
        // document.body.style.opacity = 0;
        // globalLoader && (globalLoader.style.display  = 'block');
        // setTimeout(()=>location.href = `./things/${selectedObject.name}.html?loc=${servObj.activeLocation}&sound=${servObj.soundOn}`,500);
        location.href = `./things/${selectedObject.name}.html?loc=${servObj.activeLocation}&sound=${servObj.soundOn}`;
    } 
    selectable.map(deselect);
    return;
}

function highlightObject(obj, selected) {
    animations.highlightObj(obj, selected)
    // annotations.setAnnVisibility(obj.name, selected);
}

function deselect(objName) {
    if (selectedObject && objName === selectedObject.name) return;

    const obj = scene.getObjectByName(objName);
    highlightObject(obj, false)
    // annotations.setTextVisibility(objName, false);
}